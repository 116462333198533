<template>
  <div>
    <v-card :loading="loading">
      <v-system-bar>Anlässe</v-system-bar>
      <v-data-table
        item-key="id"
        :headers="headers"
        hide-default-footer
        :items="itemsFiltered"
        disable-pagination
        :items-per-page="-1"
        :item-class="() => 'clickable'"
        @click:row="
          (item) =>
            $router.push({
              name: 'InvoiceEventDetails',
              params: { id: item.id },
            })
        "
      >
        <template v-slot:item.startDate="{ item }">
          <DateValue :value="item.startDate" />
        </template>
        <template v-slot:item.endDate="{ item }">
          <DateValue :value="item.endDate" />
        </template>
        <template v-slot:footer
          ><TableFooter
            :items="itemsFiltered"
            label="Anlässe"
            labelSingular="Schüler:in"
        /></template>
      </v-data-table>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import TableFooter from "common/components/TableFooter.vue";

const helpText = `
`;

export default defineComponent({
  components: {
    DateValue,
    TableFooter,
  },
  props: ["search"],
  data() {
    return {
      helpText,
      loading: false,
      headers: [
        { text: "", value: "status" },
        { text: "Beschreibung", value: "description" },
        { text: "Beginn", value: "startDate" },
        { text: "Ende", value: "endDate" },
      ],
      items: [],
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          !this.search ||
          el.description.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "finance/eventoverview",
      });
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
